import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {nextStepAction, prevStepAction, firstStepAction} from "../redux/actions/actions";
import {WelcomeSlide} from '../components/slides/WelcomeSlide';
import {GetReadySlide} from '../components/slides/GetReadySlide';
import {FinalSlide} from '../components/slides/FinalSlide';
import {MeasureSlide} from "../components/slides/MeasureSlide";

export const StepsController = () => {
    const dispatch = useDispatch();

    const nextStep = () => {
        dispatch(nextStepAction());
    };

    const prevStep = () => {
        dispatch(prevStepAction());
    };

    const firstStep = () => {
        dispatch(firstStepAction());
    };

    const step = useSelector(state => state.currentStep);
    let Slide;
    const slideProps = {nextStep, prevStep, stepNumber: step};
    switch (step) {
        case 0: {
            Slide = WelcomeSlide;
            break;
        }
        case 1: {
            Slide = GetReadySlide;
            break;
        }
        case 2: {
            Slide = MeasureSlide;
            slideProps.url = "https://www.youtube.com/embed/dKFeYkIrJj8?rel=0";
            slideProps.title = "Мерка 1 - Длина";
            slideProps.text = "На лист бумаги поставьте стопу и перенесите на нее вес тела. Поставьте коробок спичек вплотную к самому длинному пальцу. Не отрывая пятку от пола поднимите пальцы вверх, отметьте ручкой место соприкосновения пальцев с коробком. То же самое повторите с пяткой. Измерьте расстояние между точками. Снимите мерку с обеих ног и впишите больший результат:";
            break;
        }
        case 3: {
            Slide = MeasureSlide;
            slideProps.url = "https://www.youtube.com/embed/EHTg_uIHPiM";
            slideProps.title = "Мерка 2 - Обхват пучка";
            slideProps.text = "Положите гибкий метр под самое широкое место стопы, перед пальцами. Чаще всего оно находится около косточки большого пальца. Перенесите вес тела на стопу. Измерьте длину окружности стопы. Снимите мерку с обеих ног и впишите больший результат:";
            break;
        }
        case 4: {
            Slide = MeasureSlide;
            slideProps.url = "https://www.youtube.com/embed/chf_zIXtU9A";
            slideProps.title = "Мерка 3 - Подъем";
            slideProps.text = "Переместите метр под сгиб стопы. Чуть ниже точки сгиба стопы, измерьте длину окружности подъема. Снимите мерку с обеих ног и впишите больший результат:";
            break;
        }
        case 5: {
            Slide = FinalSlide;
            slideProps.firstStep = firstStep;
            break;
        }
        default: {
            Slide = () => <div>Ошибка, пожалуйста свяжитесь с администратором!</div>;
        }
    }

    return (
        <div className="slide-container">
            <Slide {...slideProps} />
        </div>
    );
}




